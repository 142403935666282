import { useEffect, useRef, useState } from 'react';
import { TeamPicker } from './TeamPicker';
import { Arena, GameContext, GameMode, GameState } from 'state/types';
import { TeamInfoPanel } from './TeamInfoPanel';
import { Button } from './button';
import { AllTeams } from 'state/team';
import { RandFunc, makeInitialState } from 'state/makeInitialState';
import { tick } from 'state/tick';
import { formatTime } from 'state/utils';
import { Range } from './range';
import { exportGame } from 'state/export';
import { drawRink } from 'draw/drawRink';

export interface SingleGameComponentProps {
  allTeams: AllTeams;
  rand: RandFunc;
}

export function SingleGameComponent(props: SingleGameComponentProps) {
  const { allTeams, rand } = props;

  const [teamAID, setTeamAID] = useState<string>('');
  const [teamBID, setTeamBID] = useState<string>('');

  const [gameContext, setGameContext] = useState<GameContext>();
  const [gameState, setGameState] = useState<GameState[]>([]);
  const [viewTime, setViewTime] = useState(0);
  const [currentGameState, setCurrentGameState] = useState<GameState | null>(
    null
  );

  const [playbackSpeed, setPlaybackSpeed] = useState(0);
  const [fps, setFPS] = useState(30);
  const [voiceEnabled, setVoiceEnabled] = useState(false);
  const [pauseAtEvents, setPauseAtEvents] = useState(false);
  const [drawCooldown, setDrawCooldown] = useState(false);
  const [drawFocus, setDrawFocus] = useState(false);

  const rinkRef = useRef<HTMLCanvasElement | null>(null);
  const [rinkw, setRinkw] = useState(0);
  const [rinkh, setRinkh] = useState(0);

  function updateRinkSize() {
    // but not too wide :)
    const targetw = Math.min(Math.floor(window.innerWidth * 0.95), 1200);
    setRinkw(targetw);
    setRinkh(targetw / 2);
  }

  useEffect(() => {
    updateRinkSize();
    window.addEventListener('resize', updateRinkSize);
  }, []);

  useEffect(() => {
    if (allTeams && Object.values(allTeams).length >= 2) {
      setTeamAID(Object.values(allTeams)[0].teamID);
      setTeamBID(Object.values(allTeams)[1].teamID);
    }
  }, [allTeams]);

  async function runGame() {
    const context = new GameContext();
    context.arena = new Arena();
    context.teamA = allTeams[teamAID];
    context.teamB = allTeams[teamBID];

    // initial state
    let currentState = await makeInitialState(context, Math.random);

    context.states = [currentState];

    do {
      const nextState = tick(currentState, context, rand);
      context.states.push(nextState);
      currentState = nextState;
    } while (currentState.time < 3600);

    setGameContext(context);
    setGameState(context.states);
    setViewTime(0);
  }

  const togglePlayback = () => {
    const ms = 1000 / fps;
    setPlaybackSpeed(playbackSpeed > 0 ? 0 : ms);
  };

  useEffect(() => {
    if (gameState.length > 0 && viewTime < gameState.length - 1) {
      setCurrentGameState(gameState[viewTime]);
    }
  }, [viewTime, gameState]);

  useEffect(() => {
    if (rinkRef.current && currentGameState && gameContext) {
      drawRink(rinkRef.current, currentGameState, gameContext, {
        drawCooldown,
        drawFocus,
      });
    }
  }, [rinkRef, currentGameState, gameContext, drawCooldown, drawFocus]);

  useEffect(() => {
    if (
      voiceEnabled &&
      currentGameState &&
      currentGameState.mode !== GameMode.play
    ) {
      let utterance = new SpeechSynthesisUtterance(currentGameState.message);
      window.speechSynthesis.speak(utterance);
    }
  }, [voiceEnabled, currentGameState]);

  useEffect(() => {
    if (
      gameState.length > 0 &&
      playbackSpeed > 0 &&
      viewTime < gameState.length - 1
    ) {
      const mode = gameState[viewTime].mode;
      const nextTimeout =
        playbackSpeed +
        (pauseAtEvents && mode === GameMode.minorEvent ? 2000 : 0) +
        (pauseAtEvents && mode === GameMode.majorEvent ? 5000 : 0);
      window.setTimeout(setViewTime, nextTimeout, viewTime + 1);
    }
  }, [viewTime, playbackSpeed, gameState, pauseAtEvents]);

  async function onExport() {
    if (gameContext) {
      await exportGame(gameContext);
    }
  }

  async function importGame() {
    try {
      const [fileHandle] = await window.showOpenFilePicker({
        multiple: false,
        types: [
          {
            description: 'snackhl game',
            accept: { 'application/json': ['.snackhl'] },
          },
        ],
      });
      if (fileHandle) {
        const file = await fileHandle.getFile();
        const json = await file.text();
        setGameState(JSON.parse(json) as GameState[]);
      }
    } catch (error) {
      console.log(error);
    }
  }

  return (
    <div>
      <div>
        <TeamPicker
          id="A"
          allTeams={allTeams}
          selectedTeam={teamAID}
          onSelectionChanged={setTeamAID}
        />
        <TeamPicker
          id="B"
          allTeams={allTeams}
          selectedTeam={teamBID}
          onSelectionChanged={setTeamBID}
        />
      </div>
      <Button onClick={() => runGame()}>Simulate new game</Button>
      <Button onClick={() => togglePlayback()}>
        {playbackSpeed === 0 ? 'Play' : 'Pause'}
      </Button>
      <label htmlFor="voiceEnabled">Announcer</label>
      <input
        id="voiceEnabled"
        type="checkbox"
        title="Enable Voice Announcer"
        checked={voiceEnabled}
        onChange={() => setVoiceEnabled(!voiceEnabled)}
      />
      <label htmlFor="pauseAtEvents">Pause at events</label>
      <input
        id="pauseAtEvents"
        type="checkbox"
        title="Pause at events"
        checked={pauseAtEvents}
        onChange={() => setPauseAtEvents(!pauseAtEvents)}
      />

      <Range
        min={0}
        max={gameState.length - 1}
        value={viewTime}
        id="viewTime"
        label=""
        onSetValue={setViewTime}
      />

      {currentGameState && (
        <div>
          <div>
            {`${formatTime(viewTime)} -- A: ${currentGameState.teamAScore} B: ${
              currentGameState.teamBScore
            }`}
            {currentGameState.message && (
              <span> -- {currentGameState.message}</span>
            )}
          </div>
        </div>
      )}
      <div className="mainarea">
        <div className="rink">
          <canvas
            id="rinkCanvas"
            ref={rinkRef}
            width={rinkw}
            height={rinkh}
          ></canvas>
        </div>
        <div className="teamb">
          <TeamInfoPanel
            team={'B'}
            teamInfo={allTeams[teamBID]}
            state={currentGameState}
          />
        </div>
        <div className="teama">
          <TeamInfoPanel
            team={'A'}
            teamInfo={allTeams[teamAID]}
            state={currentGameState}
          />
        </div>
      </div>

      <div>
        <Button onClick={() => onExport()}>Export</Button>
        <Button onClick={() => importGame()}>Import</Button>
      </div>
      <div>
        <input
          id="drawCooldown"
          type="checkbox"
          title="cooldown"
          onChange={(event) => {
            setDrawCooldown(event.target.checked);
          }}
        />
        <label htmlFor="drawCooldown">Draw Cooldown</label>
        <input
          id="drawFocus"
          type="checkbox"
          title="focus"
          onChange={(event) => {
            setDrawFocus(event.target.checked);
          }}
        />
        <label htmlFor="drawFocus">Draw Focus</label>
      </div>
    </div>
  );
}
