import { Actor, Arena, GameMode } from './types';

export function pickRandom(list: string[]) {
  const idx = Math.floor(Math.random() * list.length);
  return list[idx];
}

export function move(
  x: number,
  vx: number,
  bounce: number,
  limit: number
): [number, number] {
  x += vx;
  if (x > limit) {
    x = limit;
    vx = -bounce * vx;
  } else if (x < -limit) {
    x = -limit;
    vx = -bounce * vx;
  }

  return [x, vx];
}

export function moveActor(actor: Actor, bounce: number, arena: Arena) {
  [actor.x, actor.vx] = move(actor.x, actor.vx, bounce, arena.length);
  [actor.y, actor.vy] = move(actor.y, actor.vy, bounce, arena.width);
}

export function slow(vx: number, amount: number = 0.1): number {
  //
  if (vx !== 0) {
    const preSign = Math.sign(vx);
    const adjust = -preSign * amount;
    const x = vx + adjust;

    if (Math.sign(x) === preSign) {
      return x;
    }
  }
  return 0;
}

export function formatTime(theTime: number) {
  const period = 1 + Math.floor(theTime / 1200);
  const ps = 1200 - (theTime % 1200);
  const mins = String(Math.floor(ps / 60)).padStart(2, '0');
  const secs = String(ps % 60).padStart(2, '0');
  return ['Period:', period, ' ', mins, ':', secs].join('');
}

export function getGameMode(newMode: GameMode, currentMode: GameMode) {
  return newMode > currentMode ? newMode : currentMode;
}
