import { useCallback, useEffect, useState } from 'react';

export interface RangeProps {
  id: string;
  label: string;
  value: number;
  min: number;
  max: number;
  onSetValue: (newValue: number) => void;
}
export const Range = (props: RangeProps) => {
  const { id, label, value, min, max, onSetValue } = props;
  const [currentValue, setCurrentValue] = useState(min);

  const onChange = useCallback((event: any) => {
    const theValue = event.target.valueAsNumber;
    setCurrentValue(theValue);
  }, []);

  const onDecrement = () => {
    if (currentValue > min) {
      setCurrentValue(currentValue - 1);
    }
  };

  const onIncrement = () => {
    if (currentValue < max) {
      setCurrentValue(currentValue + 1);
    }
  };

  useEffect(() => {
    // notify the outside world when the value changes
    onSetValue(currentValue);
  }, [onSetValue, currentValue]);

  useEffect(() => {
    setCurrentValue(value);
  }, [value]);

  return (
    <div className="rangeComponent">
      {label && <label htmlFor={id + 'Range'}>{label + ':'}</label>}
      <button onClick={onDecrement}>{'<'}</button>
      <input
        type="range"
        id={id + 'Range'}
        min={min}
        max={max}
        step={1}
        defaultValue={currentValue}
        onChange={onChange}
      />
      {currentValue}
      <button onClick={onIncrement}>{'>'}</button>
    </div>
  );
};
