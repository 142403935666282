import { TeamInfo } from './entities/TeamInfo';
import { RandFunc } from './makeInitialState';
import { makeRandomPlayer, playerRand } from './player';
import { pickRandom } from './utils';

export type AllTeams = { [key: string]: TeamInfo };

export async function loadAllTeams(storage: Storage, rand: RandFunc) {
  let teams: AllTeams = {};

  for (let i = 0; i < storage.length; ++i) {
    const key = storage.key(i);
    if (key && key?.startsWith('TeamInfo.')) {
      const raw = storage.getItem(key);
      if (raw) {
        const theTeam = loadTeam(raw);
        teams[theTeam.teamID] = theTeam;
      }
    }
  }

  // add two random teams to the lineup
  let i = 2;
  while (i-- > 0) {
    const team = await makeRandomTeamInfo(rand);
    teams[team.teamID] = team;
  }

  return teams;
}

function loadTeam(raw: string) {
  let teamInfo = JSON.parse(raw) as TeamInfo;
  // validate and fix if anything needs fixing...
  teamInfo.color1 = teamInfo.color1 || '#DD0000';
  teamInfo.color2 = teamInfo.color2 || '#0000DD';

  teamInfo.players.forEach((player) => {
    player.shootPct = player.shootPct || 80;
    player.passPct = player.passPct || 50;
  });

  return teamInfo;
}

function makeTeamKey(team: TeamInfo) {
  return 'TeamInfo.' + team.teamID;
}

export function saveTeam(team: TeamInfo, storage: Storage) {
  const key = makeTeamKey(team);
  storage.setItem(key, JSON.stringify(team));
}

export function deleteTeam(team: TeamInfo, storage: Storage) {
  const key = makeTeamKey(team);
  storage.removeItem(key);
}

const cities = [
  'Montreal',
  'Blainville',
  'Candiac',
  'Mirabel',
  'Oka',
  'Laval',
  'Brossard',
  'Toronto',
  'Ottawa',
];

const animals = [
  'Owls',
  'Hawks',
  'Badgers',
  'Hedgehogs',
  'Crows',
  'Cats',
  'Squirrels',
  'Rabbits',
  'Otters',
  'Bees',
  'Snakes',
  'Manticores',
  'Ants',
  'Sea Cucumbers',
  'Robins',
  'Eagles',
];

async function makeRandomTeamInfo(rand: RandFunc) {
  let team = new TeamInfo();
  const city = pickRandom(cities);
  const animal = pickRandom(animals);
  team.teamName = city + ' ' + animal;
  team.teamID =
    city +
    animal +
    Math.floor(Math.random() * Number.MAX_SAFE_INTEGER).toString();

  // and a bunch of players
  const forwarders = [1, 2, 3, 4].flatMap((line) => {
    return [
      makeRandomPlayer(rand, 'C', line),
      makeRandomPlayer(rand, 'LW', line),
      makeRandomPlayer(rand, 'RW', line),
    ];
  });

  const defensers = [1, 2, 3].flatMap((line) => {
    return [
      makeRandomPlayer(rand, 'LD', line),
      makeRandomPlayer(rand, 'RD', line),
    ];
  });

  const goalers = [1, 2].flatMap((line) => {
    return [makeRandomPlayer(rand, 'G', line)];
  });

  team.players = await Promise.all([...forwarders, ...defensers, ...goalers]);

  // fix all the jersey numbers
  // TODO avoid retired numbers?

  team.players.forEach((p) => {
    while (team.players.some((o) => o.id !== p.id && o.jersey === p.jersey)) {
      // give p a new jersey
      p.jersey = playerRand(rand, 1, 99);
    }
  });

  return team;
}
