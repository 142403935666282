import { RandFunc } from './makeInitialState';
import { GameContext, GameState, PlayerGameState } from './types';
import {
  PlayerAwareness,
  dir,
  aimAt,
  dist,
  playerHasPuck,
  playerRand,
  playerSkateTo,
  playerTryTakePuck,
} from './player';

export function doGoaler(
  me: PlayerGameState,
  aware: PlayerAwareness,
  gameState: GameState,
  context: GameContext,
  rand: RandFunc
) {
  const mySpotx = -aware.opponentGoalx - dir(me.team) * 20;
  const mySpoty = 0;

  [me.aimx, me.aimy] = aimAt(
    me,
    aware.nearestTeammate.x,
    aware.nearestTeammate.y
  );
  const puckClose = dist(aware.thePuck.x - me.x, aware.thePuck.y - me.y);

  switch (true) {
    case aware.meHasPuck:
      playerHasPuck(me, aware, gameState, context, rand);
      break;

    case puckClose < 15:
      // deflect
      const ydir = Math.sign(playerRand(rand, -100, 100));
      const temp = [-aware.thePuck.vy, ydir * aware.thePuck.vx];
      [aware.thePuck.vx, aware.thePuck.vy] = temp;
      me.message = me.info.name + ' deflects the puck!';
      break;

    default:
      playerSkateTo(me, mySpotx, mySpoty);
      playerTryTakePuck(me, aware, rand);
      // if I got the puck, still stay in the crease
      if (me.focus?.id === me.id) {
        me.focus.destx = mySpotx;
        me.focus.desty = mySpoty;
      }
  }
}
