import { RandFunc } from './makeInitialState';
import { setPlayersForFaceoff, tickPlayer } from './player';
import { tickPuck } from './puck';
import { GameContext, GameMode, GameState, Puck } from './types';

export function tick(
  state: GameState,
  context: GameContext,
  rand: RandFunc
): GameState {
  const newState = { ...state };
  newState.actors = state.actors.map((a) => {
    return {
      ...a,
      focus: { ...a.focus },
    };
  });

  newState.time = state.time + 1;
  newState.mode = GameMode.play;

  // set up any special conditions...
  tickFaceOff(newState, context, rand);

  newState.actors
    .sort((a, b) => (a.sort < b.sort ? -1 : a.sort > b.sort ? 1 : 0))
    .forEach((actor) => {
      switch (actor.type) {
        case 'puck':
          tickPuck(actor, newState, context, rand);
          break;
        case 'player':
          tickPlayer(actor, newState, context, rand);
          break;
      }
    });

  newState.message = [
    ...newState.actors.filter((a) => a.message).map((a) => a.message),
  ].join('; ');

  return newState;
}

function tickFaceOff(state: GameState, context: GameContext, rand: RandFunc) {
  // check if we should start a new faceoff
  const thePuck = state.actors.find((a) => a.type === 'puck') as Puck;
  const newPeriod = state.time % 1200 === 0;

  if (thePuck?.goal || newPeriod) {
    state.mode = GameMode.faceoff;

    // reset the puck
    delete thePuck.focus;
    thePuck.x = thePuck.y = thePuck.vx = thePuck.vy = 0;
    thePuck.goal = false;

    // move all the players for faceoffs
    setPlayersForFaceoff('A', state, context, rand);
    setPlayersForFaceoff('B', state, context, rand);
  }
}
