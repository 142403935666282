import { Actor, GameContext, GameState, PlayerGameState } from './types';

function cleanupContext(context: GameContext) {
  return {
    ...context,
    states: context.states.map((gs) => cleanupGameState(gs)),
  };
}

function cleanupGameState(state: GameState) {
  return {
    ...state,
    actors: state.actors.map((a) => cleanupActor(a)),
  };
}

function cleanupActor(actor: Actor) {
  const copy = { ...actor };

  switch (actor.type) {
    case 'player':
      {
        const copyPlayer = copy as Partial<PlayerGameState>;
        delete copyPlayer.info;
      }
      break;
  }

  return copy;
}

export async function exportGame(context: GameContext) {
  try {
    const filename = 'game_' + Date.now();

    const fileHandle = await window.showSaveFilePicker({
      suggestedName: filename,
      types: [
        {
          description: 'snackhl game',
          accept: { 'application/json': ['.snackhl'] },
        },
      ],
    });
    if (fileHandle) {
      // sanitize
      const cleanContext = cleanupContext(context);
      const json = JSON.stringify(cleanContext);
      const writer = await fileHandle.createWritable();
      await writer.write(json);
      await writer.close();
    }
  } catch (error) {
    console.log(error);
  }
}
