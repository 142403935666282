import { RandFunc } from './makeInitialState';
import { GameContext, GameState, PlayerGameState } from './types';
import { PlayerAwareness, doGenericPlayer } from './player';

// yes I know -- but they all end in -er :P
export function doForwarder(
  me: PlayerGameState,
  aware: PlayerAwareness,
  gamestate: GameState,
  context: GameContext,
  rand: RandFunc
) {
  return doGenericPlayer(me, aware, gamestate, context, rand);
}
