import { AllTeams } from 'state/team';

interface TeamPickerProps {
  id: string;
  allTeams: AllTeams;
  selectedTeam?: string;
  onSelectionChanged: (id: string) => void;
}

export function TeamPicker(props: TeamPickerProps) {
  const { id, allTeams, selectedTeam, onSelectionChanged } = props;

  const fullID = 'teamPicker' + id;
  return (
    <span>
      <label htmlFor={fullID}>{id}</label>
      <select
        name={fullID}
        title="Team picker"
        value={selectedTeam}
        onChange={(e) => {
          onSelectionChanged(e.target.value);
        }}
      >
        {Object.values(allTeams).map((t) => (
          <option key={t.teamID} id={t.teamID} value={t.teamID}>
            {t.teamName}
          </option>
        ))}
      </select>
    </span>
  );
}
