import { Arena } from 'state/types';

export type ToScreenFunc = (x: number, y: number) => [number, number];

export function arenaToScreen(
  x: number,
  y: number,
  arena: Arena,
  canvas: HTMLCanvasElement
): [number, number] {
  // canvas top left is 0,0
  // arena 0,0 is center ice
  let screenX = Math.floor(
    (x + arena.length) * (canvas.width / arena.length) * 0.5
  );
  let screenY = Math.floor(
    (y + arena.width) * (canvas.height / arena.width) * 0.5
  );

  // console.log('(%d, %d) became (%d, %d)', x, y, screenX, screenY);
  return [screenX, screenY];
}
