import { PlayerInfo } from './entities/PlayerInfo';
import { TeamInfo } from './entities/TeamInfo';

export type ActorType = 'puck' | 'player' | 'ref';

export class Arena {
  // assuming center ice is at 0, 0
  // team A's end is >0
  // team B's end is <0
  // NHL is 100 feet from center
  length = 1000;

  // Anny and Logan calculated the width
  width = 425;

  // 25 feet from center
  blueLine = 250;

  // 11 feet back from the boards
  goalLine = 890;
}

export type ActorId = string;

export interface Actor {
  type: ActorType;
  sort: number;
  id: ActorId;
  focus?: {
    id?: ActorId;
    cooldown?: number;
    destx?: number;
    desty?: number;
  };
  message?: string;
  // current position of the actor
  x: number;
  y: number;
  // current velocity of the actor
  vx: number;
  vy: number;
}

export const AllPlayerPositions = ['C', 'LW', 'RW', 'LD', 'RD', 'G'] as const;
export type PlayerPosition = (typeof AllPlayerPositions)[number];

export type TeamId = 'A' | 'B';

export interface PlayerGameStats {
  puckPosessionSeconds: number;
  shots: number;
  goals: number;
  toi: number;
}

export interface PlayerGameState extends Actor {
  team: TeamId;
  info: PlayerInfo;
  aimx: number;
  aimy: number;
  stamina: number; // between 0 and 1
  gameStats: PlayerGameStats;
  shotSpeed: number[];
  face: HTMLImageElement; // face!
}

export interface Puck extends Actor {
  lastTouchedBy?: ActorId;
  goal: boolean;
}

export enum GameMode {
  play = 0,
  minorEvent = 1,
  majorEvent = 2,
  faceoff = 4,
}

export class GameState {
  time: number = 0;
  message: string = '';
  mode: GameMode = GameMode.play;
  actors: Actor[] = [];
  teamAScore = 0;
  teamBScore = 0;
}

export class GameContext {
  seed: number = 0;
  arena: Arena = new Arena();
  teamA: TeamInfo = new TeamInfo();
  teamB: TeamInfo = new TeamInfo();
  states: GameState[] = [];
}
