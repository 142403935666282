import { doGoaler } from './playerGoaler';
import { RandFunc } from './makeInitialState';
import {
  Actor,
  ActorId,
  Arena,
  GameContext,
  GameMode,
  GameState,
  PlayerGameState,
  PlayerPosition,
  TeamId,
} from './types';
import { getGameMode, moveActor, pickRandom } from './utils';
import { doDefenser } from './playerDefenser';
import { doForwarder } from './playerForwarder';
import { findPuckZone } from './puck';
import { PlayerInfo } from './entities/PlayerInfo';

const FName = [
  'Chris',
  'Chuck',
  'George',
  'Jasper',
  'James',
  'Jeff',
  'Jim',
  'Kirby',
  'Matt',
  'Nick',
  'Nate',
  'Otto',
  'Sam',
  'Sid',
  'Steve',
  'Walter',
  'Will',
];

const LName = [
  'Anders',
  'Anderson',
  'Andersson',
  'Breault',
  'Campbell',
  'Caufield',
  'Cliffton',
  'Crosby',
  'Foot',
  'Hicks',
  'Lemieux',
  'Lafleur',
  'Smith',
  'Suzuki',
  'Wall',
];

export async function makeRandomPlayer(
  rand: RandFunc,
  position: PlayerPosition,
  line: number
) {
  const r = Math.floor(rand() * 1000000);

  return {
    id: position + '_' + r,
    name: pickRandom(FName) + ' ' + pickRandom(LName),
    jersey: playerRand(rand, 1, 99),
    position: position,
    line: line,
    faceDataURL: await makeRandomFaceDataURL(rand),
    reach: position === 'G' ? 50 : 25, // goalies have more reach
    skateSpeed: 2,
    takepct: getTakePct(position),
  } as PlayerInfo;
}

function getTakePct(pos: PlayerPosition) {
  const pct = {
    G: 0.95,
    C: 0.4,
    LW: 0.4,
    RW: 0.4,
    LD: 0.6,
    RD: 0.6,
  };

  return pct[pos];
}

export interface PlayerAwareness {
  me: PlayerGameState;
  thePuck: Actor;
  puckPlayer: PlayerGameState | null;
  puckZone: TeamId | null;
  meHasPuck: boolean;
  teamHasPuck: boolean;
  opponentHasPuck: boolean;
  opponentGoalx: number;
  nearestTeammate: PlayerGameState;
  nearestOpponent: PlayerGameState;
}

function makeAwareness(
  me: PlayerGameState,
  gameState: GameState,
  context: GameContext
) {
  const thePuck =
    gameState.actors.find((a) => a.type === 'puck') || ({} as Actor);
  const puckPlayer = gameState.actors.find(
    (a) => thePuck?.focus?.id === a.id
  ) as PlayerGameState;

  return {
    me,
    thePuck: thePuck,
    puckZone: findPuckZone(thePuck, context.arena),
    puckPlayer: puckPlayer,
    meHasPuck: puckPlayer?.id === me.id,
    teamHasPuck: puckPlayer?.team === me.team,
    opponentHasPuck: puckPlayer && puckPlayer.team !== me.team,
    opponentGoalx: -dir(me.team) * context.arena.goalLine,
    nearestTeammate: findNearestPlayerOnTeam(me, gameState, me.team),
    nearestOpponent: findNearestPlayerOnTeam(me, gameState, otherTeam(me.team)),
  } as PlayerAwareness;
}

export function playerRand(rand: RandFunc, min: number = 0, max: number = 100) {
  return Math.floor((max - min) * rand() + min);
}

function distActors(a: Actor, b: Actor) {
  return dist(a.x - b.x, a.y - b.y);
}

export function dist(x: number, y: number): number {
  return Math.sqrt(x * x + y * y);
}

function otherTeam(team: TeamId) {
  return team === 'A' ? 'B' : 'A';
}

export function dir(team: TeamId): number {
  return team === 'A' ? 1 : -1;
}

async function loadPlayerFace(info: PlayerInfo) {
  const face = new Image(32, 32);
  face.src = info.faceDataURL || '';
  return face;
}

export async function makeRandomFaceDataURL(rand: RandFunc) {
  // const i = playerRand(rand, 0x1f600, 0x1f644); // smileys
  const i = playerRand(rand, 0x1f400, 0x1f43e); // animals
  const c = String.fromCodePoint(i);

  // get a data url for it...
  const canvas = document.createElement('canvas');
  canvas.width = 32;
  canvas.height = 32;
  const ctx = canvas.getContext('2d');
  if (ctx) {
    ctx.textBaseline = 'middle';
    ctx.font = "24px 'Segoe UI', 'Roboto', sans-serif";
    ctx.fillText(c, 0, 16);
  }

  const faceDataURL = canvas.toDataURL();
  return faceDataURL;
}

async function loadPlayerFaceOld(id: string, team: TeamId, rand: RandFunc) {
  const teamColor = team === 'A' ? [250, 150, 0] : [0, 250, 0];

  // const i = playerRand(rand, 0x1f600, 0x1f644); // smileys
  const i = playerRand(rand, 0x1f400, 0x1f43e); // animals
  const c = String.fromCodePoint(i);

  const dataSize = 4 * 32 * 32; // RGBA
  const data = new Uint8ClampedArray(dataSize);

  for (var x = 0; x < 32; ++x) {
    for (var y = 0; y < 32; ++y) {
      const i = 4 * (y * 32 + x);
      data[i] = teamColor[0];
      data[i + 1] = teamColor[1];
      data[i + 2] = teamColor[2];
      data[i + 3] = Math.max(0, 255 - 12 * dist(x - 16, y - 16));
    }
    // for (var i = 0; i < dataSize; i += 4) {
    // }
  }

  // get a data url for it...
  const idata = new ImageData(data, 32, 32);
  const canvas = document.createElement('canvas');
  canvas.width = 32;
  canvas.height = 32;
  const ctx = canvas.getContext('2d');
  if (ctx) {
    ctx.putImageData(idata, 0, 0);
    ctx.textBaseline = 'middle';
    ctx.font = "24px 'Segoe UI', 'Roboto', sans-serif";
    ctx.fillText(c, 0, 16);
  }

  const face = new Image(32, 32);
  const faceDataURL = canvas.toDataURL();
  face.src = faceDataURL;
  return { face, faceDataURL };
}

export async function createPlayerGameState(
  team: TeamId,
  id: ActorId,
  info: PlayerInfo,
  arena: Arena,
  rand: RandFunc
) {
  const newPlayer: PlayerGameState = {
    id: id,
    type: 'player',
    info: info,
    sort: 100,
    team: team,
    x: 0,
    y: 0,
    vx: 0,
    vy: 0,
    aimx: 0,
    aimy: 0,
    stamina: 1,
    shotSpeed: [80, 120],
    gameStats: {
      puckPosessionSeconds: 0,
      shots: 0,
      goals: 0,
      toi: 0,
    },
    face: await loadPlayerFace(info),
  };

  playerLineupForFaceoff(newPlayer, arena, rand);

  return newPlayer;
}

export function doGenericPlayer(
  me: PlayerGameState,
  aware: PlayerAwareness,
  gameState: GameState,
  context: GameContext,
  rand: RandFunc
) {
  switch (true) {
    case isPlayerOnBench(me):
      playerOnBench(me, gameState, context);
      break;

    case me.focus?.id === 'bench':
      playerGoToBench(me, context);
      break;

    case aware.meHasPuck:
      playerHasPuck(me, aware, gameState, context, rand);
      break;

    case me.focus?.cooldown !== undefined: {
      if (me.focus && me.focus.cooldown && me.focus.cooldown > 0) {
        --me.focus.cooldown;
      } else {
        delete me.focus;
      }
      break;
    }
    case me.stamina < 0.2:
      playerTired(me, context.arena);
      break;

    case distActors(me, aware.nearestOpponent) <= 10:
      playerTryCheckOpponent(me, gameState, aware, rand);
      break;

    case distActors(me, aware.nearestTeammate) < 50:
      playerAvoidTeammate(me, aware.nearestTeammate);
      break;

    case aware.teamHasPuck:
      if (me.focus?.destx && me.focus.desty) {
        playerSkateTo(me, me.focus.destx, me.focus.desty);
      } else {
        playerChooseRandomSpotInOZone(me, aware, rand);
      }
      break;

    default:
      playerChasePuck(me, aware, rand);
  }
}

function playerTired(me: PlayerGameState, arena: Arena) {
  const benchx = dir(me.team) * arena.blueLine;
  const benchy = -arena.width;
  // I'm tired!
  me.focus = {
    id: 'bench',
    destx: benchx,
    desty: benchy,
  };
  playerSkateTo(me, benchx, benchy);
}

function setPlayerOnbench(me: PlayerGameState, arena: Arena) {
  me.focus = { id: 'onbench' };
  me.x = dir(me.team) * arena.blueLine;
  me.y = -arena.width - 60;
}

function playerGoToBench(me: PlayerGameState, context: GameContext) {
  if (me.focus?.destx !== undefined && me.focus.desty !== undefined) {
    // am I close to the bench?
    if (dist(me.x - me.focus.destx, me.y - me.focus.desty) < 15) {
      setPlayerOnbench(me, context.arena);
    } else {
      playerSkateTo(me, me.focus?.destx, me.focus?.desty);
    }
  }
}

function playerOnBench(
  me: PlayerGameState,
  gameState: GameState,
  context: GameContext
) {
  if (me.stamina > 0.7) {
    // should I get back on the ice?
    const teammate = gameState.actors.find((a) => {
      const p = a as PlayerGameState;
      return (
        p.type === 'player' &&
        p.team === me.team &&
        p.info.position === me.info.position &&
        !isPlayerOnBench(p)
      );
    });

    if (!teammate) {
      // everyone with the same role as me are off the ice, I can go back on now
      delete me.focus;
      me.x = dir(me.team) * context.arena.blueLine;
      me.y = -context.arena.width;
    }
  }
}

export function aimAt(me: PlayerGameState, x: number, y: number) {
  const dx = x - me.x;
  const dy = y - me.y;
  const angle = Math.atan2(dy, dx);
  return [Math.cos(angle), Math.sin(angle)];
}

// return true if the player is still cooling from previous action
function playerCooldown(player: PlayerGameState) {
  if (player.focus?.cooldown !== undefined) {
    if (player.focus && player.focus.cooldown && player.focus.cooldown > 0) {
      --player.focus.cooldown;
      return true;
    } else {
      delete player.focus;
    }
  }

  if (player.stamina < 0.1) {
    // too tired!
    return true;
  }

  return false;
}

export function tickPlayer(
  actor: Actor,
  gameState: GameState,
  context: GameContext,
  rand: RandFunc
): Actor {
  const me = actor as PlayerGameState;
  me.message = undefined;
  me.gameStats = { ...me.gameStats };

  const aware = makeAwareness(me, gameState, context);
  playerRecoverStamina(me);

  if (isPlayerOnBench(me)) {
    playerOnBench(me, gameState, context);
  } else {
    me.gameStats.toi += 1;
    [me.aimx, me.aimy] = aimAt(me, -dir(me.team) * context.arena.goalLine, 0);

    // player's momentum
    moveActor(me, 0.1, context.arena);

    if (!playerCooldown(me)) {
      switch (me.info.position) {
        case 'G':
          doGoaler(me, aware, gameState, context, rand);
          break;

        case 'LD':
        case 'RD':
          doDefenser(me, aware, gameState, context, rand);
          break;

        case 'LW':
        case 'RW':
        case 'C':
          doForwarder(me, aware, gameState, context, rand);
          break;

        default:
          doGenericPlayer(me, aware, gameState, context, rand);
          break;
      }
    }
  }
  return me;
}

export function playerSkateTo(me: PlayerGameState, x: number, y: number) {
  const dx = x - me.x;
  const dy = y - me.y;
  const xdir = x > me.x ? 1 : -1;
  const ydir = y > me.y ? 1 : -1;
  const da = dist(dx, dy);
  const dv = dist(me.vx, me.vy);
  const speed = me.info.skateSpeed; //Math.max(me.skateSpeed * me.stamina, 0.5);

  if (da < speed) {
    me.vx = 0;
    me.vy = 0;
    if (me.focus?.id === me.id) {
      delete me.focus;
    }
  } else if (da < dv) {
    me.vx *= 0.5;
    me.vy *= 0.5;
  } else if (da < 2 * dv) {
    // start slowing down
    me.vx -= xdir * speed;
    me.vy -= ydir * speed;
  } else {
    if (Math.sign(me.vx) !== Math.sign(dx)) {
      me.vx *= 0.75;
    }

    if (Math.sign(me.vy) !== Math.sign(dy)) {
      me.vy *= 0.75;
    }

    me.vx += xdir * speed;
    me.vy += ydir * speed;

    // skating fast makes me tired
    me.stamina -= Math.max(me.vx, me.vy) * 0.0005;
  }
}

export function playerChooseRandomSpotInOZone(
  me: PlayerGameState,
  aware: PlayerAwareness,
  rand: RandFunc
) {
  // decide to skate to a random spot in offensive zone
  me.focus = {
    id: me.id,
    destx: -dir(me.team) * playerRand(rand, 250, 800),
    desty: playerRand(rand, -300, 300),
  };
}

export function playerTryTakePuck(
  me: PlayerGameState,
  aware: PlayerAwareness,
  rand: RandFunc
) {
  if (!aware.teamHasPuck) {
    // can I posess the puck?
    const puckDist = dist(aware.thePuck.x - me.x, aware.thePuck.y - me.y);
    if (puckDist < me.info.reach) {
      let takepct = me.info.takepct;

      // if the puck is just loose on the ice, higher change to just take the puck
      if (aware.thePuck.focus?.id === undefined && me.info.position !== 'G') {
        takepct += 0.3;
      }

      if (rand() < takepct) {
        me.message = me.info.name + ' takes the puck.';
        me.stamina -= 0.05;
        playerChooseRandomSpotInOZone(me, aware, rand);

        aware.thePuck.focus = { id: me.id };
        aware.thePuck.x = me.x;
        aware.thePuck.y = me.y;
        aware.thePuck.vx = 0;
        aware.thePuck.vy = 0;
      } else {
        me.focus = {
          id: me.id,
          cooldown: 2,
        };
      }
    }
  }
}

function playerChasePuck(
  me: PlayerGameState,
  aware: PlayerAwareness,
  rand: RandFunc
) {
  playerSkateTo(me, aware.thePuck.x, aware.thePuck.y);
  playerTryTakePuck(me, aware, rand);
}

export function playerHasPuck(
  me: PlayerGameState,
  aware: PlayerAwareness,
  gameState: GameState,
  context: GameContext,
  rand: RandFunc
) {
  // skate towards their focus location, or the opponent's goal if no specific focus
  playerSkateTo(
    me,
    me.focus?.destx || aware.opponentGoalx,
    me.focus?.desty || 0
  );

  // move the puck with me
  aware.thePuck.x = me.x;
  aware.thePuck.y = me.y;

  // shoot, pass or keep?
  const willShoot =
    me.info.position !== 'G' && // goalies don't shoot
    Math.abs(aware.thePuck.x) < context.arena.goalLine && // don't shoot from behind the goalline
    aware.puckZone === otherTeam(me.team) && // only shoot in o-zone
    playerRand(rand) < me.info.shootPct;

  const willPass = playerRand(rand) < me.info.passPct;

  switch (true) {
    case willShoot:
      {
        // shoot the puck! at the net -- aim was already computed
        gameState.mode = getGameMode(GameMode.minorEvent, gameState.mode);
        const shotSpeed = playerRand(rand, me.shotSpeed[0], me.shotSpeed[1]);
        const yoff = playerRand(rand, -15, 15);
        aware.thePuck.vx = me.aimx * shotSpeed;
        aware.thePuck.vy = yoff + me.aimy * shotSpeed;
        me.message = me.info.name + ' shoots the puck';
        me.gameStats.shots += 1;
        me.focus = {
          id: me.id,
          cooldown: 5,
        };

        delete aware.thePuck.focus;
      }
      break;

    case willPass:
      {
        playerPassTo(me, aware.nearestTeammate, aware, gameState, rand);
      }
      break;
  }
}

export function playerLineupForFaceoff(
  player: PlayerGameState,
  arena: Arena,
  rand: RandFunc
) {
  switch (player.info.position) {
    case 'G':
      player.x = dir(player.team) * arena.goalLine;
      player.y = 0;
      break;
    case 'C':
      player.x = dir(player.team) * 25;
      player.y = 0;
      break;
    case 'LW':
    case 'RW':
      player.x = dir(player.team) * 150;
      player.y = playerRand(rand, -200, 200);
      break;
    case 'RD':
    case 'LD':
      player.x = dir(player.team) * (arena.blueLine + 150);
      player.y = playerRand(rand, -200, 200);
      break;
  }

  player.vx = 0;
  player.vy = 0;
  delete player.focus;
}

function findNearestPlayerOnTeam(
  me: PlayerGameState,
  gameState: GameState,
  team: TeamId
): PlayerGameState | null {
  const players = gameState.actors
    .filter((a) => {
      const p = a as PlayerGameState;
      return p.team === team && p.id !== me.id;
    })
    .map((a) => {
      const p = a as PlayerGameState;
      return {
        player: p,
        d: dist(p.x - me.x, p.y - me.y),
      };
    })
    .sort((a, b) => a.d - b.d);

  return players.length > 0 ? players[0].player : null;
}

function playerTryCheckOpponent(
  me: PlayerGameState,
  gameState: GameState,
  aware: PlayerAwareness,
  rand: RandFunc
) {
  // todo pit some stats of each player against each other
  if (rand() < 0.15) {
    // we checked a guy!
    gameState.mode = getGameMode(GameMode.minorEvent, gameState.mode);
    me.message = me.info.name + ' checks ' + aware.nearestOpponent.info.name;
    me.stamina -= 0.1;
    aware.nearestOpponent.focus = {
      id: 'checked',
      cooldown: 20,
    };
    aware.nearestOpponent.vx += me.vx;
    aware.nearestOpponent.vy += me.vy;
    aware.nearestOpponent.stamina -= 0.2;
  }
}

function isPlayerOnBench(me: PlayerGameState) {
  return me.focus?.id === 'onbench';
}

function playerRecoverStamina(me: PlayerGameState) {
  if (me.stamina < 1) {
    me.stamina += isPlayerOnBench(me) ? 0.05 : 0.001;
  }
}

function playerAvoidTeammate(
  me: PlayerGameState,
  nearestTeammate: PlayerGameState
) {
  // I'm too close to a teammate, try to skate away from them
  const [x, y] = aimAt(nearestTeammate, me.x, me.y);
  me.vx += x;
  me.vy += y;
}

export function setPlayersForFaceoff(
  team: TeamId,
  state: GameState,
  context: GameContext,
  rand: RandFunc
) {
  const teamPlayers = state.actors
    .filter((a) => a.type === 'player' && (a as PlayerGameState).team === team)
    .map((a) => a as PlayerGameState);

  const staminaMap: { [key: string]: number } = {};
  const positionMap = { C: 'O', LW: 'O', RW: 'O', LD: 'D', RD: 'D', G: 'G' };
  function makeLineId(playerInfo: PlayerInfo): string {
    return positionMap[playerInfo.position] + playerInfo.line;
  }

  const numLines = Math.max(...teamPlayers.map((p) => p.info.line));

  // find out which line has most stamina
  teamPlayers.forEach((p) => {
    const lineId = makeLineId(p.info);
    staminaMap[lineId] = (staminaMap[lineId] || 0) + p.stamina;
    setPlayerOnbench(p, context.arena);
  });

  function lineupRestedLine(lines: string[]) {
    let stmax = 0;
    lines.forEach((l) => (stmax = Math.max(stmax, staminaMap[l] || 0)));
    const oline = lines.find((oline) => staminaMap[oline] === stmax);
    teamPlayers.forEach((p) => {
      if (makeLineId(p.info) === oline) {
        playerLineupForFaceoff(p, context.arena, rand);
      }
    });
  }

  // TODO: see how many lines the user actually defined...
  ['O', 'D', 'G'].forEach((l) => {
    const lineArray = [] as string[];
    for (let i = 1; lineArray.length < numLines; ++i) {
      lineArray.push(l + i);
    }

    lineupRestedLine(lineArray);
  });
}
function playerPassTo(
  me: PlayerGameState,
  target: PlayerGameState,
  aware: PlayerAwareness,
  gameState: GameState,
  rand: RandFunc
) {
  // "pass" the puck to a teammate
  gameState.mode = getGameMode(GameMode.minorEvent, gameState.mode);

  [me.aimx, me.aimy] = aimAt(me, target.x, target.y);

  const shotSpeed = playerRand(rand, me.shotSpeed[0], me.shotSpeed[1]);
  aware.thePuck.vx = me.aimx * shotSpeed;
  aware.thePuck.vy = me.aimy * shotSpeed;

  me.message = me.info.name + ' passes to ' + target.info.name;
  me.focus = {
    id: me.id,
    cooldown: 5,
  };

  delete aware.thePuck.focus;
}
