import { RandFunc } from './makeInitialState';
import { GameContext, GameState, PlayerGameState } from './types';
import {
  PlayerAwareness,
  dir,
  playerSkateTo,
  playerTryTakePuck,
  playerHasPuck,
  doGenericPlayer,
} from './player';

export function doDefenser(
  me: PlayerGameState,
  aware: PlayerAwareness,
  gameState: GameState,
  context: GameContext,
  rand: RandFunc
) {
  if (aware.meHasPuck) {
    playerHasPuck(me, aware, gameState, context, rand);
  } else if (aware.puckZone === me.team && aware.opponentHasPuck) {
    // be defensive
    const myNetx = dir(me.team) * context.arena.goalLine;

    // try to go halfway between my net and the puck
    const targetX = (aware.thePuck.x + myNetx) / 2;
    const targetY = aware.thePuck.y / 2;

    // // if we have the puck, go in the opponent blue line
    // const targetX = dir(me.team) * (aware.teamHasPuck ?
    //     (-gameState.arena.blueLine) :
    //     (gameState.arena.goalLine - 100));

    // const targetY = dir(me.team) * (me.pos === "LD" ? -1 : 1) * 150;

    playerSkateTo(me, targetX, targetY);
    playerTryTakePuck(me, aware, rand);
  } else {
    return doGenericPlayer(me, aware, gameState, context, rand);
  }
}
