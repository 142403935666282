import { Actor } from 'state/types';
import { ToScreenFunc } from './utils';

export function drawPuck(
  actor: Actor,
  context: CanvasRenderingContext2D,
  toScreen: ToScreenFunc
) {
  // draw a puck!
  const puckRadius = 3;
  context.fillStyle = 'black';
  const [px, py] = toScreen(actor.x, actor.y);

  context.beginPath();
  context.arc(px, py, puckRadius, 0, Math.PI * 2);
  context.fill();
  // context.fillRect(px - puckRadius, py - puckRadius, 2 * puckRadius, 2 * puckRadius)
}
